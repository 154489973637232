import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/Layout'
import FooterBranding from '../components/footer/FooterBranding'
import Aside from '../components/Aside'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faTwitter,  faInstagram } from "@fortawesome/free-brands-svg-icons"

const TraitementDesDonnees = props => (
  <Layout>

    <div className="container custom-container">
          <div className="content-wrapper title-page">
              <div className="content-wrapper-inner">
                    <h1>Traitement des données</h1>
                    {/* <span>{props.location.hostname}{props.location.pathname}</span> */}
                </div>
          </div>
          <div className="container-inner">
          
            <div className="content-wrapper">
            <div className="content-wrapper-inner">
                <div className="content">
                  <p>L’association JAIDE s’engage à limiter la collecte des données personnelles au strict nécessaire, dans le respect de la minimisation des données.</p>

                  <h2>Quelles sont les données concernées ?</h2>
                  <p>Les catégories de données personnelles vous concernant qui peuvent être collectées sur notre site diffèrent selon l’objectif du traitement concerné :</p>
                  <ul>
                    <li>Données d’identification pour personnes physiques : nom, prénom, coordonnées adresse e-mail</li>
                    <li>Données d&#39;identification pour personnes morales : Entreprise / Organisme, coordonnées, adresse e-mail</li>
                  </ul>
                  <p>Dans les formulaires de collecte de données personnelles présents sur nos sites (demande d’information ou d’aide, contact, inscription, dons,…), vous êtes informés du caractère obligatoire de la collecte des données par case consentement qui convient de cocher. En l’absence de renseignement d’un champ obligatoire, l’association JAIDE ne pourra pas traiter votre demande (non validation du formulaire).</p>
                
                
                  <h2>Quels traitements ?</h2>
                  <p>Nous collectons et traitons des données personnelles vous concernant aux fins de gérer, traiter et suivre vos demandes d’information, de contact, d&#39;inscription, et de vous apporter une réponse dans les meilleurs délais.</p>
                  <p>Nous ne vendons ni n’échangeons les informations vous concernant à aucun tiers ni à aucune autre organisation caritative.</p>
                
                  <h2>Quelle est la durée de conservation ?</h2>
                  <p>Nous ne conservons pas vos données à caractère personnel plus de temps que nécessaire aux fins pour lesquelles elles sont traitées.</p>
                  <p>La durée de conservation est déterminée à partir des obligations légales auxquelles nous sommes soumis et en fonction des nécessités opérationnelles de l’association JAIDE par rapport au traitement de vos données.</p>
                
                  <h2>Quels sont vos droits en matière de protection des données ?</h2>
                  <p>Conformément au RGPD et à la Loi Informatique et Libertés modifiée, vous disposez de droits sur vos données (accès, rectification, suppression, limitation, portabilité, opposition), quant à leur gestion et à leur utilisation à des fins de traitement de votre demande, que vous pouvez exercer en nous écrivant dans la rubrique “contactez-nous” ou par courrier à l’adresse suivante :</p>
                  <p>
                    <b>3 chemin du clos <br/>95 650 Puiseux-Pontoise.</b>
                  </p>

                  <p>Pour plus d’information, vous pouvez consulter nos mentions légales.</p>

                  <h2>Quand mettons-nous à jour cette politique de traitement des données personnelles ?</h2>
                  <p>La présente politique de traitement des données personnelles peut être modifiée à tout moment et à la libre discrétion de l’association JAIDE.</p>
                  <p>Si nous modifions cette politique, nous publierons la version révisée ici, avec une date de révision mise à jour.</p>
                
                  <p>Cette politique de confidentialité a été mise à jour le 11 juin 2020.</p>
                
                </div>

                <aside>
                  <Aside/>
                </aside>
                
                
                
                
                
                
              </div>
            </div>
          </div>
      </div>
    <FooterBranding/>
  </Layout>
)

export default TraitementDesDonnees
